<template>
  <el-dialog
    :title="$t('ManageGroup')"
    :visible.sync="dialogVisible"
    :close-on-click-modal="false"
    append-to-body
    :width="handleDialogWidth"
    class="manage-group"
  >
    <!-- 表格区域 -->
    <div class="table-box">
      <table>
        <!-- 未分组会员 -->
        <tr>
          <td class="group-td">
            <div class="td-flex">
              <span
                v-if="notGroupItem.isNameShow"
                class="group-name"
              >{{ notGroupItem.groupName }}</span>
              <el-input
                v-else
                v-model="notGroupItem.groupName"
                :placeholder="$t('GroupNamePlaceholder')"
                maxlength="50"
              ></el-input>
              <u
                v-show="notGroupItem.isNameShow"
                @click="notGroupNameEdit"
              ><i class="el-icon-edit"></i>{{ $t('Edit') }}</u>
            </div>
          </td>
          <td class="group-td">
            <div class="td-flex">
              <span
                v-if="notGroupItem.isRemarkShow"
                class="group-node"
              >{{ notGroupItem.node ? notGroupItem.node : '-' }}</span>
              <el-input
                v-else
                v-model="notGroupItem.node"
                :placeholder="$t('RemarkPlaceholder')"
                maxlength="100"
              ></el-input>
              <u
                v-show="notGroupItem.isRemarkShow"
                @click="notGroupNodeEdit"
              ><i class="el-icon-edit"></i>{{ $t('Edit') }}</u>
            </div>
          </td>
          <td class="number-td">
            <div class="td-flex">
              <span>{{ $t('PeopleNumber') }}</span>
              <span>{{ notGroupItem.groupNumber }}</span>
            </div>
          </td>
          <td>
          </td>
        </tr>
        <tr
          v-for="(item, index) in groupList"
          :key="item.id"
          class="draggable-item"
          draggable="true"
          @dragstart="dragstart(index)"
          @dragenter="dragenter($event, index)"
          @dragover="dragover($event, index)"
        >
          <td class="group-td">
            <div class="td-flex">
              <span
                v-if="item.isNameShow"
                class="group-name"
              >{{ item.groupName ? item.groupName : '-' }}</span>
              <el-input
                v-else
                v-model="item.groupName"
                :placeholder="$t('GroupNamePlaceholder')"
                maxlength="50"
              ></el-input>
              <u
                v-show="item.isNameShow"
                @click="groupNameEdit(item)"
              ><i class="el-icon-edit"></i>{{ $t('Edit') }}</u>
            </div>
          </td>
          <td class="group-td">
            <div class="td-flex">
              <span
                v-if="item.isRemarkShow"
                class="group-node"
              >{{ item.node ? item.node : '-' }}</span>
              <el-input
                v-else
                v-model="item.node"
                :placeholder="$t('RemarkPlaceholder')"
                maxlength="100"
              ></el-input>
              <u
                v-show="item.isRemarkShow"
                @click="groupNodeEdit(item)"
              ><i class="el-icon-edit"></i>{{ $t('Edit') }}</u>
            </div>
          </td>
          <td class="number-td">
            <div class="td-flex">
              <span>{{ $t('PeopleNumber') }}</span>
              <span>{{ item.groupNumber }}</span>
            </div>
          </td>
          <td class="oper-td">
            <el-button @click="deleteGroup(item)">{{ $t('DeleteData') }}</el-button>
            <el-button
              class="move-up"
              :disabled="index === 0"
              @click="moveUp(index)"
            >{{ $t('MoveUp') }}</el-button>
            <el-button
              class="move-down"
              :disabled="index === groupList.length - 1"
              @click="moveDown(index)"
            >{{ $t('MoveDown') }}</el-button>
          </td>
        </tr>
      </table>
    </div>
    <el-button
      v-if="showAddGroupBtn"
      class="add-group"
      @click="addGroup"
    >{{ $t('AddGroup') }}</el-button>

    <span slot="footer">
      <el-button @click="cancelFn">{{ $t('Cancle') }}</el-button>
      <el-button
        type="primary"
        :loading="verifyLoading"
        @click="submitData"
      >{{ $t('Confirm') }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import mixin from "@/assets/js/mixin";
import { deepCopy } from "@/utils/tool.js";
import api from "@/fetch/api";

export default {
  name: "ManageGroup",
  mixins: [mixin],
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    dialogVisible: {
      get() {
        return this.show;
      },
      set() {
        this.cancelFn();
      },
    },
    // 根据语言计算对话框宽度
    handleDialogWidth() {
      if (this.locale === "en-US") return "950px";
      else if (this.locale === "bahasa-Indonesia") return "1100px";
      else return "900px";
    },
  },
  data() {
    const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
    return {
      notGroupItem: {}, // 未分组
      groupList: [], // 分组数据
      // 源对象的下标
      dragIndex: "",
      // 目标对象的下标
      enterIndex: "",
      timeout: null,
      right:data.userPermission,

      // 新建组
      verifyLoading: false,
      showAddGroupBtn: true, // 是否显示新建组按钮
      addGroupId: "", // 自定义新增对象id
      operateType: "", // 操作类型
    };
  },
  created() {
    this.getGroupMemberList();
  },
  methods: {
    // 获取分组成员列表
    getGroupMemberList() {
      const locale = sessionStorage.getItem("locale");
      const languageObj = {
        "zh-CN": "Chinese",
        "en-US": "English",
        "bahasa-Indonesia": "Bahasa",
        "original-text": "non",
      };
      const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;
      const params = {
        pageNum: 1,
        pageSize: 999,
        language: languageObj[locale],
        chamberId: data.chamberId,
      };
      api.getGroupByChamberId(params).then((res) => {
        const copyData = deepCopy(res.data.data.records);
        const filterArr = [];
        if (copyData.length) {
          copyData.forEach((item) => {
            item.isNameShow = true;
            item.isRemarkShow = true;

            // 未分组成员
            if (item.unable === 1) {
              this.notGroupItem = item;
            } else {
              filterArr.push(item);
            }
          });
        }
        this.groupList = filterArr;
      });
    },

    // 取消
    cancelFn() {
      // 每次关闭对话框时，清除定时器
      clearTimeout(this.timeout);
      this.timeout = null;

      this.$emit("update:show", false);
    },

    // 长按排序
    dragstart(index) {
      this.dragIndex = index;
    },
    // dragenter 和 dragover 事件的默认行为是拒绝接受任何被拖放的元素。
    // 因此，我们要在这两个拖放事件中使用`preventDefault`来阻止浏览器的默认行为
    dragenter(e, index) {
      e.preventDefault();
      this.enterIndex = index;
      this.operateType = "drag";
      if (this.timeout !== null) {
        clearTimeout(this.timeout);
      }
      // 拖拽事件的防抖
      this.timeout = setTimeout(() => {
        if (this.dragIndex !== index) {
          const source = this.groupList[this.dragIndex];
          this.groupList.splice(this.dragIndex, 1);
          this.groupList.splice(index, 0, source);
          // 排序变化后目标对象的索引变成源对象的索引
          this.dragIndex = index;
        }
      }, 100);
    },
    dragover(e) {
      e.preventDefault();
    },

    // 新建组
    addGroup() {
      this.operateType = "add";
      this.showAddGroupBtn = false;
      const itemObj = {
        id: 999999,
        groupName: "",
        node: "",
        groupNumber: 0,
        isNameShow: false,
        isRemarkShow: false,
      };
      this.addGroupId = itemObj.id;
      this.groupList.push(itemObj);
    },

    // 未分组组名编辑
    notGroupNameEdit() {
      this.notGroupItem.isNameShow = false;
      this.operateType = "edit";
    },
    // 未分组备注编辑
    notGroupNodeEdit() {
      this.notGroupItem.isRemarkShow = false;
      this.operateType = "edit";
    },

    // 组名编辑
    groupNameEdit(row) {
      row.isNameShow = false;
      this.operateType = "edit";
    },
    // 备注编辑
    groupNodeEdit(row) {
      row.isRemarkShow = false;
      this.operateType = "edit";
    },

    // 删除分组
    deleteGroup(row) {
      if(this.right<3){
        this.$message.error(this.$t('NoRight'))
      }else{
        this.operateType = "delete";
        this.$confirm(this.$t("DeleteGroupTip"), this.$t("Attention"), {
          confirmButtonText: this.$t("Confirm"),
          cancelButtonText: this.$t("Cancle"),
          type: "warning",
        })
            .then(() => {
              const data = JSON.parse(
                  localStorage.getItem("cacheChamber")
              ).adminData;
              api
                  .zikaDeleteById({
                    groupId: row.groupId,
                    createUserId: data.id,
                  })
                  .then(() => {
                    this.$parent.getGroupMemberList();
                    this.getGroupMemberList();
                    this.$message.success({
                      message: this.$t("DeleteSuccess"),
                      duration: 1500,
                    });
                  });
            })
            .catch(() => {});
      }
    },

    // 上移
    moveUp(index) {
      this.operateType = "drag";
      if (index > 0) {
        const item = this.groupList.splice(index, 1)[0];
        this.groupList.splice(index - 1, 0, item);
      }
    },

    // 下移
    moveDown(index) {
      this.operateType = "drag";
      if (index < this.groupList.length - 1) {
        const item = this.groupList.splice(index, 1)[0];
        this.groupList.splice(index + 1, 0, item);
      }
    },

    // 确定
    submitData() {
      const data = JSON.parse(localStorage.getItem("cacheChamber")).adminData;

      // 新建组（不进行上移、下移、长按拖拽动作）
      if (this.operateType === "add") {
        let addItem = {};
        if (this.groupList.length) {
          this.groupList.forEach((item) => {
            if (this.addGroupId === item.id) addItem = item;
          });
        }

        if (!addItem.groupName.trim()) {
          return this.$message.error({
            message: this.$t("GroupNameEmpty"),
            duration: 2000,
          });
        }

        if (!addItem.node.trim()) {
          return this.$message.error({
            message: this.$t("RemarkEmpty"),
            duration: 2000,
          });
        }

        this.verifyLoading = true;
        const params = {
          groupName: addItem.groupName,
          node: addItem.node,
          chamberId: data.chamberId,
          createUserId: data.id,
        };

        api.createGroup(params).then(() => {
          this.getGroupMemberList();
          this.$parent.getGroupMemberList();
          this.$message.success({
            message: this.$t("AddSuccessTip"),
            duration: 1500,
          });
          this.showAddGroupBtn = true;
          this.addGroupId = "";
          this.verifyLoading = false;
          this.operateType = "";
        });
      } else if (this.operateType === "edit") {
        const allList = [this.notGroupItem, ...this.groupList];
        let isEmpty = false; // 判断是否为空
        if (allList.length) {
          allList.forEach((item) => {
            if (!item.groupName.trim()) {
              this.$message.error({
                message: this.$t("GroupNameEmpty"),
                duration: 2000,
              });
              isEmpty = true;
            } else if (!item.node.trim()) {
              this.$message.error({
                message: this.$t("RemarkEmpty"),
                duration: 2000,
              });
              isEmpty = true;
            }
          });
        }

        if (!isEmpty) {
          this.verifyLoading = true;
          // sort 排序
          let listLen = allList.length;
          if (allList.length) {
            allList.forEach((item) => {
              item.sort = listLen;
              listLen--;
              item.createUserId = data.id;
            });
          }
          api.zikaUpdateByIdList(allList).then(() => {
            this.getGroupMemberList();
            this.$parent.getGroupMemberList();
            this.$message.success({
              message: this.$t("EditSuccessTip"),
              duration: 1500,
            });
            this.cancelFn();
            this.verifyLoading = false;
          });
        }
      } else if (this.operateType === "drag") {
        // 1、新增组，进行上移、下移、长按拖拽动作
        if (this.addGroupId) {
          let addItem = {};
          if (this.groupList.length) {
            this.groupList.forEach((item) => {
              if (this.addGroupId === item.id) addItem = item;
            });
          }

          if (!addItem.groupName.trim()) {
            return this.$message.error({
              message: this.$t("GroupNameEmpty"),
              duration: 2000,
            });
          }

          if (!addItem.node.trim()) {
            return this.$message.error({
              message: this.$t("RemarkEmpty"),
              duration: 2000,
            });
          }

          this.verifyLoading = true;
          const params = {
            groupName: addItem.groupName,
            node: addItem.node,
            chamberId: data.chamberId,
            createUserId: data.id,
          };

          api.createGroup(params).then((res) => {
            // sort 排序
            const allList = [this.notGroupItem, ...this.groupList];
            let listLen = allList.length;
            if (allList.length) {
              allList.forEach((item) => {
                item.sort = listLen;
                listLen--;
                if (this.addGroupId === item.id) item.id = res.data.msg;
                item.createUserId = data.id;
              });
            }
            api.zikaUpdateByIdList(allList).then(() => {
              this.getGroupMemberList();
              this.$parent.getGroupMemberList();
              this.$message.success({
                message: this.$t("AddSuccessTip"),
                duration: 1500,
              });
              this.showAddGroupBtn = true;
              this.addGroupId = "";
              this.verifyLoading = false;
              this.operateType = "";
            });
          });
        } else {
          this.verifyLoading = true;
          // 2、不是新增组时，上移、下移、长按拖拽
          // sort 排序
          const allList = [this.notGroupItem, ...this.groupList];
          let listLen = allList.length;
          if (allList.length) {
            allList.forEach((item) => {
              item.sort = listLen;
              listLen--;
              item.createUserId = data.id;
            });
          }

          api.zikaUpdateByIdList(allList).then(() => {
            this.getGroupMemberList();
            this.$parent.getGroupMemberList();
            this.$message.success({
              message: this.$t("OperationSuccessTip"),
              duration: 1500,
            });
            this.cancelFn();
            this.verifyLoading = false;
          });
        }
      } else if (this.operateType === "delete" || this.operateType === "") {
        this.cancelFn();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.manage-group {
  // 表格区域
  .table-box {
    max-height: 450px;
    overflow-y: auto;
    // 滚动条宽度为0（兼容火狐）
    scrollbar-width: none;
    // 滚动条宽度为0
    &::-webkit-scrollbar {
      display: none;
    }
    table {
      width: 100%;
    }
    table,
    td {
      border: 1px solid #ebebeb;
      font-size: 12px;
      border-collapse: collapse;
    }
    tr {
      height: 35px;
    }
    .draggable-item {
      cursor: move;
    }
    td {
      padding: 0 10px;
    }
    .group-td {
      width: 250px;
    }
    .number-td {
      width: 170px;
    }
    .td-flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > .group-name,
      & > .group-node {
        flex: 1;
        width: 0;
        margin-right: 15px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & > u {
        cursor: pointer;
      }
      ::v-deep .el-input__inner {
        padding: 0;
        border: none;
      }
    }
    .oper-td {
      .el-button {
        padding: 0;
        border: none;
        background-color: #ffffff;
        color: #c50707;
        font-size: 12px;
      }
      .move-up {
        color: #8adf59;
      }
      .move-down {
        color: #bb6202;
      }
    }
  }

  .add-group {
    background-color: #589ef8;
    margin-top: 10px;
    color: #ffffff;
  }
}
</style>
<style lang="scss">
.manage-group {
  .el-dialog__header,
  .el-dialog__footer {
    text-align: center;
  }
  .el-dialog__body {
    padding: 20px;
  }
  .el-dialog__footer {
    .el-button {
      margin: 0 10px;
    }
  }
}
</style>